.mainPage {
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    grid-template-rows: 1fr 3fr 1fr;
    grid-template-areas:
            "navBar navBar navBar"
            "contentArea contentArea contentArea"
            "footer footer footer";
}

.navBar {
    grid-area: navBar;
}

.contentArea {
    grid-area: contentArea;
    margin-bottom: 100px;
}

.footer {
    grid-area: footer;
}